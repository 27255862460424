//#region IMPORT

import { CurrencyPipe, DatePipe, DecimalPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDialogModule, MatDialogRef } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { MatSliderModule } from "@angular/material/slider";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgSelectModule } from "@ng-select/ng-select";
import { NgxImageCompressService } from "ngx-image-compress";
import { IConfig, NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from "ngx-mask";
import { WebcamModule } from "ngx-webcam";
import { PipeModule } from "../functions/pipes/pipe.module";
import { AppRoutingModule } from "./app-routing.module";
import { ClaimProposalMonitoringListComponent } from "./claimproposalmonitoring/claimproposalmonitoringlist/claimproposalmonitoringlist.component";
import { HomeComponent } from "./home/home.component";
import { TabHorizontalIncludeComponent } from "./includes/tabhorizontal.include/tabhorizontal.include.component";
import { TableControlIncludeComponent } from "./includes/tablecontrol.include/tablecontrol.include.component";
import { AppComponent } from "./root/app.component";
import { SigninComponent } from "./signin/signin.component";
import { TransactionmonitoringComponent } from "./transactionmonitoring/transactionmonitoring.component";

//#endregion


//#region MODULE

const maskConfig: Partial<IConfig> =
{
	validation: false,
};


@NgModule
(
	{
		declarations: [
			AppComponent,
			HomeComponent,
			SigninComponent,
			TableControlIncludeComponent,
			TabHorizontalIncludeComponent,
			TransactionmonitoringComponent,
			ClaimProposalMonitoringListComponent,
		],
		providers: [provideEnvironmentNgxMask(maskConfig), CurrencyPipe, NgxImageCompressService, DecimalPipe, DatePipe, {
			provide: MatDialogRef,
			useValue: {}
		},],
		bootstrap: [AppComponent],
		imports: [
			AppRoutingModule,
			BrowserModule,
			FormsModule,
			HttpClientModule,
			BrowserAnimationsModule,
			MatNativeDateModule,
			MatInputModule,
			MatSliderModule,
			MatDatepickerModule,
			MatDialogModule,
			ReactiveFormsModule,
			WebcamModule,
			MatDialogModule,
			NgxMaskDirective,
			NgxMaskPipe,
			NgSelectModule,
			PipeModule
		]
	}
)

//#endregion


//#region CLASS

export class AppModule { }

//#endregion
