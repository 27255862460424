//#region IMPORT

import { BaseModel } from "./base.model";

//#endregion


//#region CLASS

export class BaseDownloadModel extends BaseModel
{
	/*
        Attributes
        Description: Attributes for BaseDownloadModel
        Author: Andrew
        Created on: 20220606            Updated on: 20220606
        Created by: Andrew              Updated by: Andrew
        Version: 1.0:1
    */

	FileName?: string;
	FilePath?: string;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion


	//#region CLEAR


	//#endregion
}

//#endregion