//#region IMPORT

import { Pipe, PipeTransform } from "@angular/core";
import { NumberConstant } from "src/app/constants/number.constant";
import { StringConstant } from "src/app/constants/string.constant";

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToDateMedium"
	}
)

//#endregion


//#region CLASS

export class ConvertToDateMedium implements PipeTransform
{
	//#region FUNCTION

	transform(value: unknown): unknown
	{
		const dateDate: Date = new Date(value as string);

		let stringHours: string = "00";

		if (dateDate.getHours() == null || dateDate.getHours() === undefined || dateDate.getHours() === 0)
		{

		}
		else
		{
			stringHours = dateDate.getHours().toString();

			if (stringHours.length === 1)
			{
				stringHours = `0${stringHours}`;
			}
			else
			{

			}
		}

		let stringMinutes: string = "00";

		if (dateDate.getMinutes() == null || dateDate.getMinutes() === undefined || dateDate.getMinutes() === 0)
		{

		}
		else
		{
			stringMinutes = dateDate.getMinutes().toString();

			if (stringMinutes.length === 1)
			{
				stringMinutes = `0${stringMinutes}`;
			}
			else
			{

			}
		}

		let stringSeconds: string = "00";

		if (dateDate.getSeconds() == null || dateDate.getSeconds() === undefined || dateDate.getSeconds() === 0)
		{

		}
		else
		{
			stringSeconds = dateDate.getSeconds().toString();

			if (stringSeconds.length === 1)
			{
				stringSeconds = `0${stringSeconds}`;
			}
			else
			{

			}
		}

		const stringDateShort: string = new ConvertToDateShort().transform(dateDate) as string;

		return `${stringDateShort} | ${stringHours}:${stringMinutes}:${stringSeconds}`;
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToDateShort"
	}
)

//#endregion


//#region CLASS

export class ConvertToDateShort implements PipeTransform
{
	//#region FUNCTION

	transform(value: unknown): unknown
	{
		if (value == null || value === undefined)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			const dateDate: Date = new Date(value as string);

			let stringMonth: string = (dateDate.getMonth() + 1).toString();

			if (stringMonth.length === 1)
			{
				stringMonth = `0${stringMonth}`;
			}
			else
			{

			}

			if (stringMonth === "01")
			{
				stringMonth = "January";
			}
			else if (stringMonth === "02")
			{
				stringMonth = "February";
			}
			else if (stringMonth === "03")
			{
				stringMonth = "March";
			}
			else if (stringMonth === "04")
			{
				stringMonth = "April";
			}
			else if (stringMonth === "05")
			{
				stringMonth = "May";
			}
			else if (stringMonth === "06")
			{
				stringMonth = "June";
			}
			else if (stringMonth === "07")
			{
				stringMonth = "July";
			}
			else if (stringMonth === "08")
			{
				stringMonth = "August";
			}
			else if (stringMonth === "09")
			{
				stringMonth = "September";
			}
			else if (stringMonth === "10")
			{
				stringMonth = "October";
			}
			else if (stringMonth === "11")
			{
				stringMonth = "November";
			}
			else if (stringMonth === "12")
			{
				stringMonth = "Desember";
			}
			else
			{
				return StringConstant.STRING_CHARACTER_DASH;
			}

			return `${dateDate.getDate()} ${stringMonth} ${dateDate.getFullYear()}`;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "initiateWithoutUTC"
	}
)

//#endregion


//#region CLASS

export class InitiateWithoutUTC implements PipeTransform
{
	//#region FUNCTION

	transform(value: unknown): unknown
	{
		if (value == null || value === undefined)
		{
			return "";
		}
		else
		{
			const dateDate: Date = new Date(value as string).initiateWithoutUTC();

			return dateDate;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertEmptyToDash"
	}
)

//#endregion


//#region CLASS

export class ConvertEmptyToDash implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "" || stringValue === "NaN")
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			return stringValue as string;
		}
	}

	//#endregion
}

//#region PIPE

@Pipe
(
	{
		name: "currencyValue"
	}
)

//#endregion


//#region CLASS

export class CurrencyValue implements PipeTransform
{
	//#region FUNCTION

	transform(numberValue?: any): number
	{
		const stringNumberValue = String(numberValue)[0];

		if (numberValue == null || numberValue === undefined)
		{
			return 0;
		}
		else if(stringNumberValue === "0")
		{
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			return numberValue;
		}
		else
		{
			if(Number.isInteger(numberValue))
			{

			}
			else
			{
				try
				{
					numberValue = Number(numberValue);
				}
				catch (error)
				{

				}
			}

			numberValue = numberValue.toLocaleString(["ban", "id"]);

			return numberValue as number;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertSeparateEnumSpace"
	}
)

//#endregion


//#region CLASS

export class ConvertSeparateEnumSpace implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		stringValue = stringValue.replace( /([a-z])([A-Z])/g, "$1 $2" );
		stringValue = stringValue.charAt(0).toUpperCase() + stringValue.slice(1);

		return stringValue as string;
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertNoDivisionToCustomer"
	}
)

//#endregion


//#region CLASS

export class ConvertNoDivisionToCustomer implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return "Customer";
		}
		else
		{
			return stringValue as string;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertByteForPreview"
	}
)

//#endregion


//#region CLASS

export class ConvertByteForPreview implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue != null && stringValue !== undefined && stringValue !== "")
		{
			const numberValue: number = parseInt(stringValue, 10);

			if (numberValue < 1000)
			{
				return numberValue.toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Bytes";
			}
			else if (numberValue < 1000000)
			{
				return (numberValue / 1000).toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Kb";
			}
			else if (numberValue < 1000000000)
			{
				return (numberValue / 1000000).toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Mb";
			}
			else
			{
				return (numberValue / 1000000).toFixed(NumberConstant.NUMBER_VALUE_DECIMAL_PREVIEW).toString() + " Gb";
			}
		}
		else
		{
			return StringConstant.STRING_CHARACTER_EMPTY + " Bytes";
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertExtensionToIcon"
	}
)

//#endregion


//#region CLASS

export class ConvertExtensionToIcon implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return "../../assets/icons/sakina/icon_file_image.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_PDF)
		{
			return "../../assets/icons/sakina/icon_file_pdf.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_IMAGE_JPEG || stringValue === StringConstant.STRING_FORMAT_IMAGE_JPG || stringValue === StringConstant.STRING_FORMAT_IMAGE_PNG)
		{
			return "../../assets/icons/sakina/icon_file_image.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_COMPRESS_RAR || stringValue === StringConstant.STRING_FORMAT_COMPRESS_ZIP)
		{
			return "../../assets/icons/sakina/icon_file_compress.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_EXCEL || stringValue === StringConstant.STRING_FORMAT_DOCUMENT_EXCELX)
		{
			return "../../assets/icons/sakina/icon_file_excel.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_CSV)
		{
			return "../../assets/icons/sakina/icon_file_csv.svg";
		}
		else if (stringValue === StringConstant.STRING_FORMAT_DOCUMENT_WORD || stringValue === StringConstant.STRING_FORMAT_DOCUMENT_WORDX)
		{
			return "../../assets/icons/sakina/icon_file_word.svg";
		}
		else
		{
			return "../../assets/icons/sakina/icon_file_image.svg";
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToTime"
	}
)

//#endregion


//#region CLASS

export class ConvertToTime implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue: unknown): unknown
	{
		if(stringValue === undefined || stringValue === null || stringValue === "")
		{
			return "";
		}
		else
		{
			const dateDate: Date = new Date(stringValue as string);

			let stringHours: string = "00";

			if (dateDate.getHours() == null || dateDate.getHours() === undefined || dateDate.getHours() === 0)
			{

			}
			else
			{
				stringHours = dateDate.getHours().toString();

				if (stringHours.length === 1)
				{
					stringHours = `0${stringHours}`;
				}
				else
				{

				}
			}

			let stringMinutes: string = "00";

			if (dateDate.getMinutes() == null || dateDate.getMinutes() === undefined || dateDate.getMinutes() === 0)
			{

			}
			else
			{
				stringMinutes = dateDate.getMinutes().toString();

				if (stringMinutes.length === 1)
				{
					stringMinutes = `0${stringMinutes}`;
				}
				else
				{

				}
			}

			let stringSeconds: string = "00";

			if (dateDate.getSeconds() == null || dateDate.getSeconds() === undefined || dateDate.getSeconds() === 0)
			{

			}
			else
			{
				stringSeconds = dateDate.getSeconds().toString();

				if (stringSeconds.length === 1)
				{
					stringSeconds = `0${stringSeconds}`;
				}
				else
				{

				}
			}

			return `${stringHours}:${stringMinutes}:${stringSeconds}`;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToDateNamingFile"
	}
)

//#endregion


//#region CLASS

export class ConvertToDateNamingFile implements PipeTransform
{
	//#region FUNCTION

	transform(value: unknown): unknown
	{
		if (value == null || value === undefined)
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}
		else
		{
			const dateDate: Date = new Date(value as string);

			let stringMonth: string = (dateDate.getMonth() + 1).toString();

			let stringHours: string = "00";

			if (stringMonth.length === 1)
			{
				stringMonth = `0${stringMonth}`;
			}
			else
			{

			}

			stringHours = dateDate.getHours().toString();

			if (stringHours.length === 1)
			{
				stringHours = `0${stringHours}`;
			}
			else
			{

			}

			let stringMinutes: string = "00";

			if (dateDate.getMinutes() == null || dateDate.getMinutes() === undefined || dateDate.getMinutes() === 0)
			{

			}
			else
			{
				stringMinutes = dateDate.getMinutes().toString();

				if (stringMinutes.length === 1)
				{
					stringMinutes = `0${stringMinutes}`;
				}
				else
				{

				}
			}

			let stringSeconds: string = "00";

			if (dateDate.getSeconds() == null || dateDate.getSeconds() === undefined || dateDate.getSeconds() === 0)
			{

			}
			else
			{
				stringSeconds = dateDate.getSeconds().toString();

				if (stringSeconds.length === 1)
				{
					stringSeconds = `0${stringSeconds}`;
				}
				else
				{

				}
			}

			return `${dateDate.getDate()}-${stringMonth}-${dateDate.getFullYear()}_${stringHours}.${stringMinutes}.${stringSeconds}`;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertCapitalizeEachWord"
	}
)

//#endregion


//#region CLASS

export class ConvertCapitalizeEachWord implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): string
	{
		if (stringValue == null || stringValue === undefined || stringValue === "")
		{
			return StringConstant.STRING_CHARACTER_DASH;
		}

		stringValue = stringValue.toLowerCase();

		const arrayString = stringValue.split(" ");

		for (let numberIndex = 0; numberIndex < arrayString.length; numberIndex++)
		{
			arrayString[numberIndex] = arrayString[numberIndex].charAt(0).toUpperCase() + arrayString[numberIndex].slice(1);
		}

		const stringResult: string = arrayString.join(" ");

		return stringResult;

	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertEmptyToNumberZero"
	}
)

//#endregion


//#region CLASS

export class ConvertEmptyToNumberZero implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: any): any
	{
		if (stringValue == null || stringValue === undefined || stringValue === "" || stringValue === "NaN")
		{
			return "0";
		}
		else
		{
			return stringValue as string;
		}
	}

	//#endregion
}

//#endregion


//#region PIPE

@Pipe
(
	{
		name: "convertToMaskingValue"
	}
)

//#endregion


//#region CLASS

export class ConvertToMaskingValue implements PipeTransform
{
	//#region FUNCTION

	transform(stringValue?: string, numberConstantValue: number = NumberConstant.NUMBER_COUNT_VALUE_IDENTIFICATIONNUMBER): any
	{
		if (stringValue == null || stringValue === undefined || stringValue === "" || stringValue === "NaN")
		{
			return "-";
		}
		else
		{
			if(numberConstantValue === NumberConstant.NUMBER_COUNT_VALUE_IDENTIFICATIONNUMBER)
			{
				return stringValue.replaceAt(NumberConstant.NUMBER_COUNT_INDEX_IDENTIFICATIONNUMBER, "*****");
			}
			else
			{
				return stringValue;
			}
		}
	}

	//#endregion
}

//#endregion
