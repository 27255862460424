//#region IMPORT

import { Injectable } from "@angular/core";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class TypeServices
{
	//#region DECLARATION

	//#endregion


	//#region CONSTRUCTOR

	constructor()
	{

	}

	//#endregion


	//#region SETTER

	setEnvironmentsType(environment): void
	{
		localStorage.setItem("ServiceType", environment);
		return;
	}

	//#endregion


	//#region GETTER

	getEnvironmentsType(): boolean
	{

		if(localStorage.getItem("ServiceType") === "false")
		{
			return false;
		}
		else
		{
			return true;
		}
	}


	getEnvironment(): string
	{
		if(localStorage.getItem("ServiceType") === "false")
		{
			const stringEnvironmentType: string = "DEVELOPMENT";
			return stringEnvironmentType;
		}
		else
		{
			const stringEnvironmentType: string = "PRODUCTION";
			return stringEnvironmentType;
		}
	}


	//#endregion


	//#region CLEAR

	clearEnvironmentsType(): void
	{
		localStorage.removeItem("ServiceType");
	}




	//#endregion
}

//#endregion