//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { PegadaianModel } from "../models/pegadaian.model";
import { UserModel } from "../models/user.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region  CLASS

export class PegadaianService extends BaseService
{

	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectPegadaianByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_PEGADAIAN_SELECTPEGADAIANBYEMAIL);
	}

	//#endregion


	//#region VERIFY and SIGNOUT

	verifyPegadaianByEmail(interfaceGeneralService: GeneralServiceInterface, modelUser: UserModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelUser), WebAddressConstant.STRING_URL_PEGADAIAN_VERIFYPEGADAIANBYEMAIL);
	}

	signOut(interfaceGeneralService: GeneralServiceInterface, modelPegadaian: PegadaianModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelPegadaian), WebAddressConstant.STRING_URL_PEGADAIAN_SIGNOUT);
	}

	//#endregion
}

//#endregion
