//#region IMPORT

import { ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { BaseDownloadModel } from "./bases/basedownload.model";
import { ResponseModel } from "./response.model";

//#endregion


//#region CLASS

export class DownloadDocumentModel extends BaseDownloadModel
{
	/*
		Attribute - START
		Description : Attribute for product GENERAL.
		Author: Abdullah Fahmi.
		Created on : Tuesday, 28 February 2023.				Updated on : -.
		Created by : Abdullah Fahmi.						Updated by : -.
		Version : 1.0:0.
	*/

	Data?: string;
	Name?: string;
	Extension?: string;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	validateDocumentFileName(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Pengunduhan Lampiran.");

		if (this.FileName == null || this.FileName === undefined)
		{
			modelResponse.MessageContent = "Nama file dokumen kosong!";
		}
		else
		{
			modelResponse.MessageContent = "Form is filled correctly.";
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
		}

		return modelResponse;
	}

	//#endregion


	//#region  VALIDATION

	//#endregion
}

//#endregion
