//#region IMPORT

import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { UserSessionModel } from "src/app/models/usersession.model";
import { PwaService } from "src/app/services/pwa.service";
import { SessionService } from "src/app/services/session.service";
import { TypeServices } from "src/app/services/toogle.service";
import { PegadaianService } from "src/app/services/pegadaian.service";
import { PegadaianModel } from "src/app/models/pegadaian.model";
import { ENUM_LETTER_ROLE } from "../../constants/enum.constant";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-home",
		templateUrl: "./home.component.html",
		styleUrls: ["./home.component.sass"]
	}
)

//#endregion


//#region CLASS

export class HomeComponent extends BaseAuthourizeComponent
{
	//#region DECLARATION

	public _booleanNavigation: boolean | null;
	public _booleanNavigationItem: boolean | null;
	public _booleanNavigationItem2: boolean | null;
	public _booleanNavigationItem3: boolean | null;
	public _booleanNavigationDashboard: boolean | null;
	public _booleanNavigationEmaterai: boolean | null;
	public _booleanNavigationItemReportMonitoring: boolean | null;
	public _booleanNavigationItemAchievement: boolean | null;
	public _booleanNavigationItemCMS: boolean | null;
	public _booleanNavigationItemCMSFAQ: boolean | null;
	public _booleanNavigationMenuDBD: boolean | null;
	public _booleanEnvironmentType: boolean;
	public _booleanNavigationDeclaration: boolean | null;
	public _booleanNavigationSubmission: boolean | null;
	public _booleanNavigationEBudgeting: boolean | null;
	public _booleanNavigationReimbursement: boolean | null;
	public _booleanNavigationApproval: boolean | null;
	public _booleanNavigationRcsa: boolean | null;
	public _booleanNavigationItemDigitalization: boolean | null;
	public _booleanNavigationItemRAS: boolean | null;

	public _typeService: TypeServices;

	public _enumLetterRole = ENUM_LETTER_ROLE;

	public _stringEnvironmentType?: string | null;
	public _stringConstant = StringConstant;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _servicePegadaian: PegadaianService, serviceSession: SessionService, router: Router, public _servicePWA: PwaService)
	{
		super(serviceSession, router);

		this._booleanNavigation = null;
		this._booleanNavigationItem = null;
		this._booleanNavigationItem2 = null;
		this._booleanNavigationItem3 = null;
		this._booleanNavigationDashboard = null;
		this._booleanNavigationEmaterai = null;
		this._booleanNavigationItemReportMonitoring = null;
		this._booleanNavigationItemAchievement = null;
		this._booleanEnvironmentType = false;
		this._booleanNavigationReimbursement = null;
		this._booleanNavigationApproval = null;
		this._booleanNavigationRcsa = null;
		this._booleanNavigationItemDigitalization = null;
		this._booleanNavigationItemCMS = null;
		this._booleanNavigationItemCMSFAQ = null;
		this._booleanNavigationMenuDBD = null;
		this._booleanNavigationItemRAS = null;
		this._booleanNavigationDeclaration = null;
		this._booleanNavigationSubmission = null;
		this._booleanNavigationEBudgeting = null;
		this._typeService = new TypeServices();

	}

	//#endregion


	//#region NAVIGATION

	ngOnInit(): void
	{
		this.initialData();
	}

	//#endregion


	//#region FUNCTION

	initialData(): void
	{
		this._booleanEnvironmentType = this._typeService.getEnvironmentsType();
		this.getStringEnvironment();
	}

	toggleNavigation(): void
	{
		this._booleanNavigation = !this._booleanNavigation;
		this._booleanNavigationItem = false;
		this._booleanNavigationItem2 = false;
		this._booleanNavigationDashboard = false;
		this._booleanNavigationEmaterai = false;
		this._booleanNavigationItemDigitalization = false;
		this._booleanNavigationDeclaration = false;
		this._booleanNavigationSubmission = false;
		this._booleanNavigationEBudgeting = false;
	}

	clickSwicthEnvironments(): void
	{
		if(this._booleanEnvironmentType === true)
		{

			this._typeService.setEnvironmentsType("true");
			this.goToReload();
			// this.initialData();
		}
		else
		{
			this._typeService.setEnvironmentsType("false");
			this.goToReload();
			// this.initialData();
		}
	}

	getStringEnvironment()
	{
		this._stringEnvironmentType = this._typeService.getEnvironment();
	}

	//#endregion


	//#region WEB SERVICE

	callSignOut(): void
	{
		const componentCurrent: HomeComponent = this;
		const modelUserSession: UserSessionModel | null = this._serviceSession.getUserSession();

		if (modelUserSession != null)
		{
			this._servicePegadaian.signOut
			(
				{
					success(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
							{
								componentCurrent.signOut();
							}
							else
							{
								componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
							}
						});
					},
					fail(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSignOut(); });
					},
					signOut(modelResponse): void
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
					}
				}, new PegadaianModel()
			);
		}
		else
		{
			componentCurrent.signOut();
		}
	}

	//#endregion


	//#region NAVIGATION

	goToTransactionMonitoring(): void
	{
		this._router.navigate(["home", "transactionmonitoring"]);
		this.toggleNavigation();
	}

	goToClaimMonitoring(): void
	{
		this._router.navigate(["home", "claimmonitoring", "list"]);
		this.toggleNavigation();
	}

	goToReload(): void
	{
		window.location.reload();
	}

	//#endregion
}

//#endregion
