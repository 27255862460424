//#region IMPORT

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WebAddressConstant } from "../constants/webaddress.constant";
import { GeneralServiceInterface } from "../interfaces/general.service.interface";
import { TableModel } from "../models/bases/table.model";
import { ThirdPartyDashboardModel } from "../models/thirdpartydashboard.model";
import { DownloadDocumentModel } from "../models/downloaddocument.model";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class ThirdPartyDashboardService extends BaseService
{
	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region SELECT

	selectDashboardPegadaianByAttributes(interfaceGeneralService: GeneralServiceInterface, modelTable: TableModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelTable), WebAddressConstant.STRING_URL_THIRDPARTYDASHBOARD_SELECTDASHBOARDPRODUCTPEGADAIANBYATTRIBUTES);
	}

	selectDashboardPegadaianByAttributesForExcel(interfaceGeneralService: GeneralServiceInterface, modelThirdPartyDashboard: ThirdPartyDashboardModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelThirdPartyDashboard), WebAddressConstant.STRING_URL_THIRDPARTYDASHBOARD_SELECTDASHBOARDPRODUCTPEGADAIANBYATTRIBUTESFOREXCEL);
	}

	//#endregion


	//#region DOWNLOAD

	downloadDashboardPegadaianByRoute(interfaceGeneralService: GeneralServiceInterface, modelDownloadDocument: DownloadDocumentModel): void
	{
		return this.callServicePrivate(interfaceGeneralService, JSON.stringify(modelDownloadDocument), WebAddressConstant.STRING_URL_DOWNLOADDOCUMENT_DOWNLOADDASHBOARDFORPEGADAIANBYROUTE);
	}

	//#endregion
}

//#endregion
