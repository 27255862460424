<div id="divBodyHome" appCheckDevTools>
	<nav [ngClass]="{ 'NavShow' : _booleanNavigation, 'NavHide' : !_booleanNavigation, 'NavNone' : _booleanNavigation === null }">
		<figure>
			<div>
				<img *ngIf="_modelPegadaian.PhotoURL" id="imageProfilePicture">
				<span *ngIf="!_modelPegadaian.PhotoURL" id="spanProfileNameInitial">{{ _modelUserSignIn.getNameInitial() }}</span>
			</div>

			<figcaption>
				<span id="spanProfileName">{{ _modelUserSignIn.getName() }}</span>
				<span id="spanProfilePosition">Pegadaian</span>
			</figcaption>

			<a class="Underline" (click)="callSignOut();">Sign out</a>

			<form>
				<!-- <h3>{{ _stringEnvironmentType }}</h3> -->
				<label class="LabelSwitch">
					<input type="checkbox" [(ngModel)]="_booleanEnvironmentType" (change)="clickSwicthEnvironments()" name="checkboxType">
					<span class="SpanSlider Round"></span>
				</label>
			</form>
		</figure>

		<ul class="ListNavigation">
			<li class="ListItemNavigation">
				<a (click)="goToTransactionMonitoring();">Transaction Monitoring</a>
			</li>
			<li class="ListItemNavigation">
				<a (click)="goToClaimMonitoring();">Claim Monitoring</a>
			</li>
		</ul>

		<!-- <div id="divNotificationInstallList" *ngIf="!this._servicePWA._booleanInstalled">
			<div>
				<span>Pegadaian</span>
				<p class="ParagraphNotificationInstallOthers" *ngIf="!this._servicePWA._booleanIOS">Avoid any hassle to return this app and gives you better experience!</p>
				<p class="ParagraphNotificationInstallIOS" *ngIf="this._servicePWA._booleanIOS">To install this web app on your device tap the 'Share' button and then 'Add to Home Screen' button.</p>
			</div>

			<input type="button" value="Install" id="buttonNotificationInstallList" *ngIf="!this._servicePWA._booleanIOS" (click)="this._servicePWA.installWebsite();">
		</div> -->

		<!-- <a href="https://www.ssltrust.com.au/security-report?domain=dbd.web.brinesia.app" rel="nofollow" target="new"><img src="https://seals.ssltrust.com.au/report_large.png" style="border: 0px;"></a> -->

		<!-- <iframe src="https://securityscorecard.com/security-rating/badge/brinesia.app" width="256" height="100" frameBorder="0" id="iframeSignIn"></iframe> -->
	</nav>

	<main [ngClass]="{ 'MainHide' : _booleanNavigation, 'MainShow' : !_booleanNavigation }">
		<router-outlet></router-outlet>
	</main>

	<input type="button" name="buttonNavigation" (click)="toggleNavigation();" id="buttonNavigation">

	<img id="imageLogoHeader" src="../../assets/logos/sakina/logo_briinsurance_header.svg" alt="BRI Insurance - Logo Header">
</div>
