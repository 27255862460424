//#region IMPORT

import { ENUM_POLICY_STATUS, ENUM_RESPONSE_STATE } from "../constants/enum.constant";
import { GeneralModel } from "./bases/general.model";
import { ResponseModel } from "./response.model";

//endregion


//region CLASS

export class ThirdPartyDashboardModel extends GeneralModel
{
	/*
		Attribute - START
		Description : Attribute for response model.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Tuesday, 29 Decemeber 2020.			    Updated on : Wednesday, 6 January 2021.
		Created by : Andri Septiawan.						    Updated by : Ibrahim Aziz.
		Version : 1.0:2.
	*/

	ID?: number;
	ThirdPartyCreditNumber?: number;
	PolicyholderIdentificationNumber?: string;
	PolicyholderFullName?: string;
	ThirdPartyCreditAgreementDate?: Date;
	ProductCode?: string;
	PremiumTotalAmount?: number;
	ThirdPartyTenor?: number;
	ReferenceNumber?: string;
	PolicyStatus?: ENUM_POLICY_STATUS;
	PolicyholderThirdPartyBranchCode?: string;
	PolicyholderThirdPartyBranchArea?: string;
	PolicyholderThirdPartyBranchKanwil?: string;
	TokenProductGeneral?: string;
	CreatedStartDate?: Date;
	CreatedEndDate?: Date;
	CreatedOn?: Date;

	/* Attribute - END */


	//#region CONSTRUCTOR

	constructor()
	{
		super();
	}

	//#endregion


	//#region VALIDATION

	validatePeriod(): ResponseModel
	{
		const modelResponse: ResponseModel = new ResponseModel();
		modelResponse.setForValidation("Product General Report");

		if ((this.CreatedStartDate == null || this.CreatedStartDate === undefined) || (this.CreatedEndDate == null || this.CreatedEndDate === undefined))
		{
			modelResponse.MessageContent = "Masukkan periode tanggal.";
		}
		else if (this.CreatedEndDate < this.CreatedStartDate)
		{
			modelResponse.MessageContent = "Periode tanggal berakhir tidak boleh kurang dari tanggal mulai.";
		}
		else
		{
			modelResponse.State = ENUM_RESPONSE_STATE.Success;
			modelResponse.MessageContent = "Form is filled correctly.";
		}

		return modelResponse;
	}

	//#endregion
}

//#endregion
