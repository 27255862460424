<div id="divBodyDashboard" class="DivBodyGeneral">
    <header class="HeaderGeneral">
		<h2>Transaction Monitoring</h2>
		<p>Dashboard traksaksi Pegadaian</p>
    </header>

	<div id="divMainFinancialRatioKonsolidasi" class="DivContainerListHorizontal FlexColumn">
		<form>
			<div class="DivForm">
				<h3>Pencarian</h3>

                <fieldset>
					<div class="DivFormHorizontalContainer">
						<div class="DivForm2Column">
							<label for="datePeriod">Periode</label>
							<mat-form-field appearance="fill">
								<mat-label>Masukkan periode tanggal</mat-label>
								<mat-date-range-input [rangePicker]="picker">
									<input matStartDate placeholder="Start date" [value]="_dateStart" (dateChange)="getStartDate($event)">
									<input matEndDate placeholder="End date" [value]="_dateEnd" (dateChange)="getEndDate($event)">
								</mat-date-range-input>
								<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-date-range-picker #picker></mat-date-range-picker>
							</mat-form-field>

							<label for="selectChannel">Produk</label>
							<select type="text" id="selectChannel" name="modelSelectChannel" required [(ngModel)]="_modelThirdPartyDashboardRequest.ProductCode">
								<ng-container>
									<option [ngValue]="undefined" hidden>Pilih Produk</option>
									<option [ngValue]="undefined">Semua</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTCODE_DASHBOARD_UMI_MYHOUSE">{{_stringConstant.STRING_PRODUCT_UMI_MYHOUSE}}</option>
									<option [ngValue]="_stringConstant.STRING_PRODUCTCODE_DASHBOARD_UMI_DAMAGEOFBUSINESSPLACE">{{_stringConstant.STRING_PRODUCT_UMI_DAMAGEOFBUSINESSPLACE}}</option>
								</ng-container>
							</select>
						</div>

						<div class="DivForm2Column">
							<label for="selectStatus">Status</label>
							<select type="text" id="selectStatus" name="modelSelectStatus" required [(ngModel)]="_modelThirdPartyDashboardRequest.PolicyStatus">
								<ng-container>
									<option [ngValue]="undefined" hidden>Pilih Status</option>
									<option [ngValue]="undefined">Semua</option>
									<option [ngValue]="_enumPolicyStatus.Active">Aktif</option>
									<option [ngValue]="_enumPolicyStatus.Inactive">Tidak Aktif</option>
								</ng-container>
							</select>

							<label for="selectPerformance" class="MarginTop35">Nomor Referensi</label>
							<input type="text" onkeydown="return /[a-zA-Z0-9]/i.test(event.key)" name="modelNumberReference" placeholder="Masukkan Nomor Referensi" maxlength="25" [(ngModel)]="_modelThirdPartyDashboardRequest.ReferenceNumber">
						</div>
					</div>
				</fieldset>
			</div>

            <div class="DivForm2ColumnButton">
				<input type="button" name="buttonSearch" value="Cari" (click)="callSelectDashboardPegadaianByAttributes(this, true)">
				<input type="button" name="buttonExportExcel" value="Expor Excel" (click)="callGenerateExcelForDashboardTransactionReport(this)">
			</div>
		</form>

		<div id="divStatusDiscount" class="DivTableContainerHorizontal">
			<div class="DivTableContainerHorizontal">
				<div id="divMenuAllProductListContainer" class="DivTableContainer MarginLeft0">
					<app-tablecontrolinclude [_modelTableForm]="_modelTable" (_eventEmitterSelect)="setEventEmitterSelect($event);"></app-tablecontrolinclude>
					<table class="TableListReportMonitoring">
						<thead>
							<tr>
								<th>No</th>
								<th>No. Kredit</th>
								<th>Nomor Referensi</th>
								<th>NIK</th>
								<th>Nama</th>
								<th>Tanggal Akad Kredit</th>
								<th>Tanggal Transaksi</th>
								<th>Jenis Produk</th>
								<th>Nilai Premi</th>
								<th>Tenor</th>
								<th>Status Polis</th>
								<th>Unit Kerja</th>
								<th>Area</th>
								<th>Kanwil</th>
								<th>Ikhtisar Pertanggungan</th>
							</tr>
						</thead>

						<tbody>
							<tr class="TableRowCenter" *ngFor="let modelThirdPartyDashboard of _arrayModelThirdPartyDashboard; let numberIndex = index">
								<td>{{numberIndex+1}}</td>
								<td>{{modelThirdPartyDashboard.ThirdPartyCreditNumber | convertEmptyToDash}}</td>
								<td>{{modelThirdPartyDashboard.ReferenceNumber | convertEmptyToDash}}</td>
								<td>{{modelThirdPartyDashboard.PolicyholderIdentificationNumber | convertToMaskingValue | convertEmptyToDash}}</td>
								<td>{{modelThirdPartyDashboard.PolicyholderFullName | convertEmptyToDash}}</td>
								<td>{{modelThirdPartyDashboard.ThirdPartyCreditAgreementDate | convertToDateShort | convertEmptyToDash}}</td>
								<td>{{modelThirdPartyDashboard.CreatedOn | convertToDateShort | convertEmptyToDash}}</td>
								<ng-container *ngIf="modelThirdPartyDashboard.ProductCode === _stringConstant.STRING_PRODUCTCODE_DASHBOARD_UMI_MYHOUSE">
									<td>UMI Rumahku</td>
								</ng-container>
								<ng-container *ngIf="modelThirdPartyDashboard.ProductCode === _stringConstant.STRING_PRODUCTCODE_DASHBOARD_UMI_DAMAGEOFBUSINESSPLACE">
									<td>UMI KTU</td>
								</ng-container>
								<ng-container *ngIf="modelThirdPartyDashboard.ProductCode !== _stringConstant.STRING_PRODUCTCODE_DASHBOARD_UMI_DAMAGEOFBUSINESSPLACE && modelThirdPartyDashboard.ProductCode !== _stringConstant.STRING_PRODUCTCODE_DASHBOARD_UMI_MYHOUSE">
									<td>-</td>
								</ng-container>
								<td>{{modelThirdPartyDashboard.PremiumTotalAmount | currencyValue | convertEmptyToDash}}</td>
								<td>{{modelThirdPartyDashboard.ThirdPartyTenor | convertEmptyToDash}} bulan</td>
								<ng-container *ngIf="modelThirdPartyDashboard.PolicyStatus === _enumPolicyStatus.Active">
									<td>Aktif</td>
								</ng-container>
								<ng-container *ngIf="modelThirdPartyDashboard.PolicyStatus === _enumPolicyStatus.Inactive">
									<td>Tidak Aktif</td>
								</ng-container>
								<ng-container *ngIf="modelThirdPartyDashboard.PolicyStatus !== _enumPolicyStatus.Active && modelThirdPartyDashboard.PolicyStatus !== _enumPolicyStatus.Inactive">
									<td>-</td>
								</ng-container>
								<td>{{modelThirdPartyDashboard.PolicyholderThirdPartyBranchCode | convertEmptyToDash}}</td>
								<td>{{modelThirdPartyDashboard.PolicyholderThirdPartyBranchArea | convertEmptyToDash}}</td>
								<td>{{modelThirdPartyDashboard.PolicyholderThirdPartyBranchKanwil | convertEmptyToDash}}</td>
								<td>
									<input type="button" class="ButtonPDF" (click)="downloadDashboardPegadaianByRoute(this, modelThirdPartyDashboard.ReferenceNumber ?? '')">
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>
