//#region IMPORT

import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ENUM_POLICY_STATUS, ENUM_RESPONSE_STATE } from "src/app/constants/enum.constant";
import { ResponseCodeConstant } from "src/app/constants/responsecode.constant";
import { StringConstant } from "src/app/constants/string.constant";
import { TableModel } from "src/app/models/bases/table.model";
import { ResponseModel } from "src/app/models/response.model";
import { SessionService } from "src/app/services/session.service";
import * as xlsx from "xlsx";
import { ThirdPartyDashboardModel } from "src/app/models/thirdpartydashboard.model";
import { ThirdPartyDashboardService } from "src/app/services/thirdpartydashboard.service";
import { DownloadDocumentModel } from "src/app/models/downloaddocument.model";
import { BaseAuthourizeComponent } from "../bases/baseauthourize.component";
import { TableControlIncludeComponent } from "../includes/tablecontrol.include/tablecontrol.include.component";

//#endregion


//#region COMPONENT

@Component({
	selector: "app-transactionmonitoring",
	templateUrl: "./transactionmonitoring.component.html",
	styleUrls: ["./transactionmonitoring.component.sass"]
})

//#endregion


//#region CLASS

export class TransactionmonitoringComponent extends BaseAuthourizeComponent
{

	//#region DECLARATION

	@ViewChild(TableControlIncludeComponent) private _componentTableControlInclude!: TableControlIncludeComponent;

	public _arrayModelThirdPartyDashboard: Array<ThirdPartyDashboardModel>;
	public _arrayModelThirdPartyDashboardReport: Array<ThirdPartyDashboardModel>;

	public _modelTable: TableModel;
	public _modelThirdPartyDashboard: ThirdPartyDashboardModel;
	public _modelThirdPartyDashboardRequest: ThirdPartyDashboardModel;

	public _stringConstant = StringConstant;
	public _enumPolicyStatus = ENUM_POLICY_STATUS;

	public _dateStart: Date | undefined;
	public _dateEnd: Date | undefined;

	public _matrixExportExcelDashboard: any = [];

	public _fileWorkBookExcel: xlsx.WorkBook;

	public _elementHTMLDivMenuAllProductListContainer: HTMLElement | any;

	//#endregion


	//#region CONSTRUCTOR

	constructor(private _serviceThirdPartyDashboard: ThirdPartyDashboardService, serviceSession: SessionService, router: Router)
	{
		super(serviceSession, router);

		this._dateStart = undefined;
		this._dateEnd = undefined;

		this._modelThirdPartyDashboard = new ThirdPartyDashboardModel();
		this._modelThirdPartyDashboardRequest = new ThirdPartyDashboardModel();
		this._modelTable = new TableModel();

		this._arrayModelThirdPartyDashboard = [];
		this._arrayModelThirdPartyDashboardReport = [];

		this._fileWorkBookExcel = xlsx.utils.book_new();

		this._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
	}

	//#endregion


	//#region

	ngOnInit(): void
	{
		this.callSelectDashboardPegadaianByAttributes(this, true);
	}

	//#endregion


	//#region NAVIGATION

	goToTransactionMonitoringDetail(stringToken: string)
	{
		this._router.navigate(["home", "transactionmonitoring", "detail", stringToken]);
	}

	//#endregion


	//#region WEB SERVICE

	public callSelectDashboardPegadaianByAttributes(componentCurrent: TransactionmonitoringComponent, booleanFirstSearch: boolean): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		const modelThirdPartyDashboardRequest = new ThirdPartyDashboardModel();
		modelThirdPartyDashboardRequest.ProductCode = componentCurrent._modelThirdPartyDashboardRequest.ProductCode;
		modelThirdPartyDashboardRequest.PolicyStatus = componentCurrent._modelThirdPartyDashboardRequest.PolicyStatus;
		modelThirdPartyDashboardRequest.ReferenceNumber = componentCurrent._modelThirdPartyDashboardRequest.ReferenceNumber;
		modelThirdPartyDashboardRequest.CreatedStartDate = componentCurrent._dateStart;
		modelThirdPartyDashboardRequest.CreatedEndDate = componentCurrent._dateEnd;

		componentCurrent._modelTable.Search = JSON.stringify(modelThirdPartyDashboardRequest);

		if(booleanFirstSearch)
		{
			componentCurrent._modelTable.Page = 1;
		}

		componentCurrent._serviceThirdPartyDashboard.selectDashboardPegadaianByAttributes
		({
			success(modelResponse: ResponseModel): void
			{
				if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
				{
					if (modelResponse.Data !== undefined)
					{
						componentCurrent._modelTable.setModelFromString(modelResponse.Data);

						if (componentCurrent._modelTable.Result !== undefined)
						{
							componentCurrent._arrayModelThirdPartyDashboard = [];

							let arrayModelThirdPartyDashboardTemp: Array<ThirdPartyDashboardModel> = [];
							arrayModelThirdPartyDashboardTemp = JSON.parse(componentCurrent._modelTable.Result);
							componentCurrent._modelTable.Result = undefined;

							let modelThirdPartyDashboard: ThirdPartyDashboardModel;

							for(const modelThirdPartyDashboardTemp of arrayModelThirdPartyDashboardTemp)
							{
								modelThirdPartyDashboard = new ThirdPartyDashboardModel();
								modelThirdPartyDashboard.setModelFromObject(modelThirdPartyDashboardTemp);
								componentCurrent._arrayModelThirdPartyDashboard.push(modelThirdPartyDashboard);
							}

							componentCurrent._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
							componentCurrent._elementHTMLDivMenuAllProductListContainer.style.display = "block";

							componentCurrent._functionUserInterface.updateLoadingProgress();
							componentCurrent._componentTableControlInclude.setButtonState();
						}
						else
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () =>
						{
							componentCurrent._elementHTMLDivMenuAllProductListContainer = document.getElementById("divMenuAllProductListContainer");
							componentCurrent._elementHTMLDivMenuAllProductListContainer.style.display = "none";
							componentCurrent._modelTable.Page = 1;
							componentCurrent._modelTable.RowPerPage = 20;
						});
					}
				}
				else
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
				}
				componentCurrent._functionUserInterface.updateLoadingProgress();
			},
			fail(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectDashboardPegadaianByAttributes(componentCurrent, true); });
			},
			signOut(modelResponse: ResponseModel): void
			{
				componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
			}
		}, componentCurrent._modelTable);
	}

	public callSelectProductGeneralReportByAttributesForExcel(componentCurrent: TransactionmonitoringComponent, success: () => void): void
	{
		componentCurrent._functionUserInterface.setLoadingProgress(1);

		componentCurrent.setArrayAllProductReportEmpty();

		const modelThirdPartyDashboardRequest: ThirdPartyDashboardModel = new ThirdPartyDashboardModel();
		modelThirdPartyDashboardRequest.CreatedStartDate = componentCurrent._dateStart;
		modelThirdPartyDashboardRequest.CreatedEndDate = componentCurrent._dateEnd;
		modelThirdPartyDashboardRequest.ProductCode = componentCurrent._modelThirdPartyDashboardRequest.ProductCode;
		modelThirdPartyDashboardRequest.ReferenceNumber = componentCurrent._modelThirdPartyDashboardRequest.ReferenceNumber;
		modelThirdPartyDashboardRequest.PolicyStatus = componentCurrent._modelThirdPartyDashboardRequest.PolicyStatus;

		const modelResponseValidation: ResponseModel = modelThirdPartyDashboardRequest.validatePeriod();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			componentCurrent._serviceThirdPartyDashboard.selectDashboardPegadaianByAttributesForExcel
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.ServiceResponseCode === ResponseCodeConstant.STRING_RESPONSECODE_SERVICE_SUCCESS)
					{
						if (modelResponse.Data !== undefined)
						{
							let arrayModelThirdPartyDashboardReportTemp: Array<ThirdPartyDashboardModel> = [];
							arrayModelThirdPartyDashboardReportTemp = JSON.parse(modelResponse.Data);

							let modelThirdPartyDashboard: ThirdPartyDashboardModel;
							for(const modelProductGeneralTemp of arrayModelThirdPartyDashboardReportTemp)
							{
								modelThirdPartyDashboard = new ThirdPartyDashboardModel();
								modelThirdPartyDashboard.setModelFromObject(modelProductGeneralTemp);
								componentCurrent._arrayModelThirdPartyDashboardReport.push(modelThirdPartyDashboard);
							}
						}
						else
						{
							componentCurrent.setArrayAllProductReportEmpty();
						}
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
					componentCurrent._functionUserInterface.updateLoadingProgress();
					success();
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, () => {}); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelThirdPartyDashboardRequest);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => {});
		}
	};

	public callGenerateExcelForDashboardTransactionReport(componentCurrent: TransactionmonitoringComponent): void
	{
		this._functionUserInterface.setLoadingProgress(1);
		this._fileWorkBookExcel = xlsx.utils.book_new();

		componentCurrent.callSelectProductGeneralReportByAttributesForExcel(componentCurrent, () =>
		{
			componentCurrent.getDashboardExportToExcel();

			//#region SAVE TO FILE

			const stringDatePeriod = ""+componentCurrent.getPeriodeFull(componentCurrent._dateStart)+" s/d "+componentCurrent.getPeriodeFull(componentCurrent._dateEnd);

			// const stringDatePeriod: string = ""+componentCurrent._dateStart?.getDate()+componentCurrent._dateStart?.getMonth()+componentCurrent._dateStart?.getFullYear()+
			// " s/d "+componentCurrent._dateEnd?.getDate()+componentCurrent._dateEnd?.getMonth()+componentCurrent._dateEnd?.getFullYear();

			let stringProduct: string = "";
			if(componentCurrent._modelThirdPartyDashboardRequest.ProductCode === StringConstant.STRING_PRODUCTCODE_DASHBOARD_UMI_MYHOUSE)
			{
				stringProduct = "UMI Rumahku";
			}
			else if(componentCurrent._modelThirdPartyDashboardRequest.ProductCode === StringConstant.STRING_PRODUCTCODE_DASHBOARD_UMI_DAMAGEOFBUSINESSPLACE)
			{
				stringProduct = "UMI KTU";
			}
			else
			{
				stringProduct = "ALL";
			}
			const stringFileName: string = "Laporan Data Transaksi BRINS Pegadaian "+stringProduct+" Produk Periode "+stringDatePeriod+".xlsx";

			xlsx.writeFile(componentCurrent._fileWorkBookExcel, stringFileName);

			const modelResponseExcel: ResponseModel = new ResponseModel();
			modelResponseExcel.MessageTitle = "Export Excel";
			modelResponseExcel.MessageContent = "File excel download success! Please wait.";
			this._functionUserInterface.showDialogFromModelResponse(modelResponseExcel, () => { this._functionUserInterface.updateLoadingProgress(); });

			//#endregion

			this._functionUserInterface.updateLoadingProgress();
		});
	}

	downloadDashboardPegadaianByRoute(componentCurrent: TransactionmonitoringComponent, stringReferenceNumber: string)
	{
		this._functionUserInterface.setLoadingProgress(1);

		const modelDownloadDocument: DownloadDocumentModel = new DownloadDocumentModel();
		modelDownloadDocument.FileName = stringReferenceNumber;

		let modelResponseValidation: ResponseModel = new ResponseModel();
		modelResponseValidation = modelDownloadDocument.validateDocumentFileName();

		if (modelResponseValidation.State === ENUM_RESPONSE_STATE.Success)
		{
			this._serviceThirdPartyDashboard.downloadDashboardPegadaianByRoute
			({
				success(modelResponse: ResponseModel): void
				{
					if (modelResponse.Data !== undefined)
					{
						const modelProductGeneralDocumentDownload: DownloadDocumentModel = new DownloadDocumentModel();
						modelProductGeneralDocumentDownload.setModelFromString(modelResponse.Data);

						if (modelProductGeneralDocumentDownload !== undefined)
						{
							componentCurrent._functionUserInterface.updateLoadingProgress();
							// eslint-disable-next-line max-len
							if (modelProductGeneralDocumentDownload.Name == null || modelProductGeneralDocumentDownload.Extension == null || modelProductGeneralDocumentDownload.Data == null)
							{

							}
							else
							{
								componentCurrent._functionUserInterface.downloadFileBlob(modelProductGeneralDocumentDownload.Name ?? "", "application/pdf", "pdf", modelProductGeneralDocumentDownload.Data ?? "");
							}
						}
						else
						{
							componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
						}
						componentCurrent._functionUserInterface.updateLoadingProgress();
					}
					else
					{
						componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { });
					}
				},
				fail(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponseWithRetry(modelResponse, () => { componentCurrent.downloadDashboardPegadaianByRoute(componentCurrent, stringReferenceNumber); });
				},
				signOut(modelResponse: ResponseModel): void
				{
					componentCurrent._functionUserInterface.showDialogFromModelResponse(modelResponse, () => { componentCurrent.signOut(); });
				}
			}, modelDownloadDocument);
		}
		else
		{
			this._functionUserInterface.showDialogFromModelResponse(modelResponseValidation, () => {});
		}
	}

	//#endregion


	//#region SETTER

	setEventEmitterSelect(modelTableUpdate: TableModel): void
	{
		this._modelTable = modelTableUpdate;
		this.callSelectDashboardPegadaianByAttributes(this, false);
	}

	private setStartDate(dateStart?: Date): void
	{
		if (dateStart !== undefined && dateStart !== null)
		{
			this._dateStart = dateStart.clearUTC();
			this._modelThirdPartyDashboardRequest.CreatedStartDate = dateStart.clearUTC();
		}
		else
		{

		}
	}

	private setEndDate(dateEnd?: Date): void
	{
		if (dateEnd !== undefined && dateEnd !== null)
		{
			this._dateEnd = dateEnd.clearUTC();
			this._modelThirdPartyDashboardRequest.CreatedEndDate = dateEnd.clearUTC();
		}
		else
		{

		}
	}

	private setArrayAllProductReportEmpty(): void
	{
		this._arrayModelThirdPartyDashboardReport = [];
	}

	//#endregion


	//#region GETTER

	getStartDate(event): void
	{
		this.setStartDate(event.value);
	};

	getEndDate(event): void
	{
		this.setEndDate(event.value);
	};

	getPeriodeFull(date: Date | undefined): string
	{
		let stringDate = "";
		let stringMonth = "";
		const stringYear = date?.getFullYear().toString();

		const numberDate = date?.getDate() ?? 1;
		const numberMonth = (date?.getMonth() ?? 1) + 1;
		const arrayRange = [1, 2, 3, 4, 5, 6, 7, 8, 9];


		for(const numberElement of arrayRange)
		{
			if(numberDate === numberElement)
			{
				stringDate = "0"+numberElement.toString();
				break;
			}
			else
			{
				stringDate = numberDate.toString();
			}
		}

		for(const numberElement of arrayRange)
		{
			if(numberMonth === numberElement)
			{
				stringMonth = "0"+numberElement.toString();
				break;
			}
			else
			{
				stringMonth = numberMonth.toString();
			}
		}

		return stringDate+stringMonth+stringYear;
	}

	//#endregion


	//#region FUNCTION MATRIX EXCEL

	public getDashboardExportToExcel(): void
	{
		//#region INITIALIZATION DECLARATION VARIABLE

		this._matrixExportExcelDashboard = [];
		this._matrixExportExcelDashboard[0] = [];
		let numberIndexRow: number = 0;

		//#endregion


		//#region SET HEADER EXCEL

		this._matrixExportExcelDashboard[numberIndexRow] = [];
		this._matrixExportExcelDashboard[numberIndexRow].push("No. Kredit");
		this._matrixExportExcelDashboard[numberIndexRow].push("NIK");
		this._matrixExportExcelDashboard[numberIndexRow].push("Nama");
		this._matrixExportExcelDashboard[numberIndexRow].push("Tanggal Akad Kredit");
		this._matrixExportExcelDashboard[numberIndexRow].push("Tanggal Transaksi");
		this._matrixExportExcelDashboard[numberIndexRow].push("Produk");
		this._matrixExportExcelDashboard[numberIndexRow].push("Nilai Premi");
		this._matrixExportExcelDashboard[numberIndexRow].push("Tenor");
		this._matrixExportExcelDashboard[numberIndexRow].push("Nomor Referensi");
		this._matrixExportExcelDashboard[numberIndexRow].push("Status Polis");
		this._matrixExportExcelDashboard[numberIndexRow].push("Unit Kerja");
		this._matrixExportExcelDashboard[numberIndexRow].push("Area");
		this._matrixExportExcelDashboard[numberIndexRow].push("Kanwil");

		//#endregion


		//#region BINDING DATA TO EXCEL

		if(this._arrayModelThirdPartyDashboardReport !== undefined)
		{
			for (const modelThirdPartyDashboardReport of this._arrayModelThirdPartyDashboardReport)
			{
				numberIndexRow++;

				this._matrixExportExcelDashboard[numberIndexRow] = [];
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.ThirdPartyCreditNumber ?? "-");
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.PolicyholderIdentificationNumber ?? "-");
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.PolicyholderFullName ?? "-");
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.ThirdPartyCreditAgreementDate ?? "-");
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.CreatedOn ?? "-");
				if(modelThirdPartyDashboardReport.ProductCode === StringConstant.STRING_PRODUCTCODE_DASHBOARD_UMI_MYHOUSE)
				{
					this._matrixExportExcelDashboard[numberIndexRow].push("UMI Rumahku");
				}
				else if(modelThirdPartyDashboardReport.ProductCode === StringConstant.STRING_PRODUCTCODE_DASHBOARD_UMI_DAMAGEOFBUSINESSPLACE)
				{
					this._matrixExportExcelDashboard[numberIndexRow].push("UMI KTU");
				}
				else
				{
					this._matrixExportExcelDashboard[numberIndexRow].push("-");
				}
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.PremiumTotalAmount ?? "-");
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.ThirdPartyTenor ?? "-");
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.ReferenceNumber ?? "-");
				if(modelThirdPartyDashboardReport.PolicyStatus === ENUM_POLICY_STATUS.Active)
				{
					this._matrixExportExcelDashboard[numberIndexRow].push("Aktif");
				}
				else if(modelThirdPartyDashboardReport.PolicyStatus === ENUM_POLICY_STATUS.Inactive)
				{
					this._matrixExportExcelDashboard[numberIndexRow].push("Tidak Aktif");
				}
				else
				{
					this._matrixExportExcelDashboard[numberIndexRow].push("-");
				}
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.PolicyholderThirdPartyBranchCode ?? "-");
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.PolicyholderThirdPartyBranchArea ?? "-");
				this._matrixExportExcelDashboard[numberIndexRow].push(modelThirdPartyDashboardReport.PolicyholderThirdPartyBranchKanwil ?? "-");
			}

			//#region GENERATE WORKSHEET

			const workSheet: xlsx.WorkSheet = xlsx.utils.aoa_to_sheet(this._matrixExportExcelDashboard, { cellDates: true, dateNF: "dd-mmmm-yyyy" });

			//#endregion


			//#region GENERATE WORKBOOK AND ADD THE WORKSHEET

			xlsx.utils.book_append_sheet(this._fileWorkBookExcel, workSheet, "Report Transaction Monitoring");

			//#endregion
		}
		else
		{

		}

		//#endregion
	}

	//#endregion

}

//#endregion
