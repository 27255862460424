//#region IMPORT

import { ENUM_EMPLOYMENT_GRADE, ENUM_EMPLOYMENT_TYPE } from "../constants/enum.constant";
import { BaseModel } from "./bases/base.model";
import { DivisionModel } from "./division.model";
import { FormationModel } from "./formation.model";
import { PositionModel } from "./position.model";
import { UserModel } from "./user.model";


//#endregion


//#region CLASS

export class PegadaianModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for Pegadaian model, used for user who has Pegadaian role.
		Author: Andri Septiawan and Ibrahim Aziz.
		Created on : Wednesday, 06 December 2023. 			Updated on : Wednesday, 06 December 2023.
		Created by : Abdullah Fahmi.						Updated by : -.
		Version : 1.0:0.
	*/

	ID?: number;
	UserID?: number;

	DivisionID?: number;
	FormationID?: number;
	PositionID?: number;

	EmployeeNumber?: string;
	EmploymentType?: ENUM_EMPLOYMENT_TYPE;
	EmploymentGrade?: ENUM_EMPLOYMENT_GRADE;

	Token?: string;
	BranchCode?: string;
	DeviceID?: string;
	Culture?: string;

	PhotoURL?: string;
	PhotoFileName?: string;
	PhotoOrder?: number;
	PhotoWidth?: number;
	PhotoHeight?: number;
	PhotoFormat?: string;
	PhotoNote?: string;

	modelUser?: UserModel;
	modelDivision?: DivisionModel;
	modelFormation?: FormationModel;
	modelPosition?: PositionModel;

	constructor()
	{
		super();
	}

	/* Attribute - END */
}

//#endregion
