//#region IMPORT

import { BaseModel } from "./bases/base.model";

//#endregion


//#region CLASS

export class EnumGeneralModel extends BaseModel
{
	/*
		Attribute - START
		Description : Attribute for enum general.
		Author: Eka Saputra.
		Created on : Tuesday, 7 March 2023.		 			Updated on : .
		Created by : Eka Saputra.							Updated by : .
		Version : 1.0:1.
	*/

	ID?: any;
	Name?: string;

	/* Attribute - END */
}

//#endregion