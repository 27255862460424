//#region IMPORT

import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { QuicklinkModule, QuicklinkStrategy } from "ngx-quicklink";
import { GeneralAuthorizedGuardService } from "../services/guards/generalauthorized.guard";
import { PublicGuardService } from "../services/guards/public.guard.service";
import { HomeComponent } from "./home/home.component";
import { SigninComponent } from "./signin/signin.component";
import { TransactionmonitoringComponent } from "./transactionmonitoring/transactionmonitoring.component";

//#endregion


//#region DECLARATION

const arrayRoutes: Routes =
[
	{
		path: "",
		redirectTo: "/",
		pathMatch: "full"
	},
	{
		path: "",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "signin",
		component: SigninComponent,
		canActivate: [PublicGuardService]
	},
	{
		path: "home",
		component: HomeComponent,
		canActivate: [GeneralAuthorizedGuardService],
		children:
		[
			{
				path: "transactionmonitoring",
				component: TransactionmonitoringComponent
			},
			{
				path: "claimmonitoring",
				loadChildren: () => import("./claimproposalmonitoring/claimproposalmonitoring.module").then(pageModule => pageModule.ClaimProposalMonitoringModule),
			},
		]
	},
];

//#endregion


//#region MODULE

@NgModule
(
	{
		imports:
		[
			QuicklinkModule,
			RouterModule.forRoot(arrayRoutes,
				{
					preloadingStrategy: QuicklinkStrategy,
					onSameUrlNavigation: "reload",
				})
		],
		exports: [RouterModule]
	}
)

//#endregion


//#region CLASS

export class AppRoutingModule { }

//#endregion
