//#region IMPORT

import { Platform } from "@angular/cdk/platform";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BaseService } from "./bases/base.service";
import { SessionService } from "./session.service";

//#endregion


//#region INJECTABLE

@Injectable
(
	{
		providedIn: "root"
	}
)

//#endregion


//#region CLASS

export class PwaService extends BaseService
{
	//#region DECLARATIONS

	public _booleanInstalled: boolean = false;
	public _booleanIOS: boolean = false;
	private _promptEvent: any;

	//#endregion


	//#region CONSTRUCTOR

	constructor(clientHTTP: HttpClient, serviceSession: SessionService, private _platform: Platform)
	{
		super(clientHTTP, serviceSession);
	}

	//#endregion


	//#region FUNCTION

	public setupInstaller(): void
	{
		let booleanInstalled: boolean = false;
		const booleanStandalone = window.matchMedia("(display-mode: standalone)").matches;

		// eslint-disable-next-line @typescript-eslint/dot-notation
		if (document.referrer.startsWith("android-app://") || window.navigator["standalone"] || booleanStandalone)
		{
			booleanInstalled = true;
		}
		else
		{
			booleanInstalled = false;
		}


		if (booleanInstalled)
		{
			this.hideNotificationInstall();
		}
		else
		{
			if (this._platform.IOS)
			{
			// eslint-disable-next-line @typescript-eslint/dot-notation
				const booleanStandaloneMode = ("standalone" in window.navigator) && (window.navigator["standalone"]);

				if (!booleanStandaloneMode)
				{
					this._booleanIOS = true;
					this.showNotificationInstall(true);
				}
				else
				{
					this._booleanIOS = false;
					this.hideNotificationInstall();
				}
			}
			else
			{
				this.hideNotificationInstall();
				const serviceCurrent: PwaService = this;

				window.addEventListener("beforeinstallprompt", (event: any) =>
				{
					serviceCurrent.showNotificationInstall(false);
					serviceCurrent._promptEvent = event;
				});

				window.addEventListener("appinstalled", () =>
				{
					serviceCurrent.hideNotificationInstall();
					serviceCurrent._promptEvent = null;
				});
			}
		}
	}

	public installWebsite(): void
	{
		this._promptEvent.prompt();
		this._promptEvent.userChoice.then((choiceResult) =>
		{
			if (choiceResult.outcome === "accepted")
			{
				this.hideNotificationInstall();
				this._promptEvent = null;
			}
			else
			{

			}
		});
	}

	private showNotificationInstall(booleanIOS: boolean): void
	{
		this._booleanInstalled = false;

		const serviceCurrent: PwaService = this;
		const elementHTMLDivNotificationInstall = document.getElementById("divNotificationInstall");
		const elementHTMLButtonNotificationInstall = document.getElementById("buttonNotificationInstall");

		if (elementHTMLDivNotificationInstall == null || elementHTMLDivNotificationInstall === undefined)
		{

		}
		else
		{
			elementHTMLDivNotificationInstall.style.display = "flex";

			const elementHTMLButtonNotificationClose = document.getElementById("buttonNotificationClose");

			if (elementHTMLButtonNotificationClose == null || elementHTMLButtonNotificationClose === undefined)
			{

			}
			else
			{
				elementHTMLButtonNotificationClose.onclick = function()
				{
					elementHTMLDivNotificationInstall.style.display = "none";
				};
			}

			if (booleanIOS)
			{
				const elementHTMLParagraphNotificationInstallIOS = document.getElementById("paragraphNotificationInstallIOS");
				const elementHTMLParagraphNotificationInstallOthers = document.getElementById("paragraphNotificationInstallOthers");

				if (elementHTMLParagraphNotificationInstallIOS == null || elementHTMLParagraphNotificationInstallIOS === undefined)
				{

				}
				else
				{
					elementHTMLParagraphNotificationInstallIOS.style.display = "block";
				}

				if (elementHTMLParagraphNotificationInstallOthers == null || elementHTMLParagraphNotificationInstallOthers === undefined)
				{

				}
				else
				{
					elementHTMLParagraphNotificationInstallOthers.style.display = "none";
				}

				if (elementHTMLButtonNotificationInstall == null || elementHTMLButtonNotificationInstall === undefined)
				{

				}
				else
				{
					elementHTMLButtonNotificationInstall.style.display = "none";
				}
			}
			else
			{
				if (elementHTMLButtonNotificationInstall == null || elementHTMLButtonNotificationInstall === undefined)
				{

				}
				else
				{
					elementHTMLButtonNotificationInstall.onclick = null;
					elementHTMLButtonNotificationInstall.onclick=function()
					{
						serviceCurrent.installWebsite();
					};
				}
			}
		}
	}

	private hideNotificationInstall(): void
	{
		this._booleanInstalled = true;

		const elementHTMLDivNotificationInstall = document.getElementById("divNotificationInstall");

		if (elementHTMLDivNotificationInstall == null || elementHTMLDivNotificationInstall === undefined)
		{

		}
		else
		{
			elementHTMLDivNotificationInstall.style.display = "none";
		}
	}

	//#endregion
}

//#endregion