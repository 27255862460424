//#region IMPORT

import { Component } from "@angular/core";

//#endregion


//#region COMPONENT

@Component
(
	{
		selector: "app-root",
		templateUrl: "./app.component.html",
		styleUrls: ["./app.component.sass"]
	}
)

//#endregion


//#region CLASS

export class AppComponent
{
	title = "Volga-Website";

	//#region INITIALIZE

	ngOnInit(): void
	{
	}

	//#endregion
}

//#endregion
