//#region IMPORT

import { environment } from "src/environments/environment";

//#endregion


//#region CLASS

export class WebAddressConstant
{
	//#region IPADDRESS

	static STRING_WEBADDRESS_WEBSITE_IPADRESS_BRINS: string = "www.brins.co.id";

	//#endregion


	//#region URL API

	static STRING_URL_USER_API: string = environment.URL_USER_API;
	static STRING_URL_PRODUCT_API: string = environment.URL_PRODUCT_API;
	static STRING_URL_POLICY_API: string = environment.URL_POLICY_API;
	static STRING_URL_BROADCAST_API: string = environment.URL_BROADCAST_API;
	static STRING_URL_CLAIM_API: string = environment.URL_CLAIM_API;
	static STRING_URL_INTEGRATION_API: string = environment.URL_INTEGRATION_API;

	//#endregion


	//#region HANDSHAKE SERVICE

	static STRING_PATH_CONTROLLER_HANDSHAKE: string = "/Handshake";

	static STRING_URL_HANDSHAKE_GENERATETOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/generateToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshToken`;
	static STRING_URL_HANDSHAKE_SELECTUSERBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/selectUserByToken`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORDIGITALMICROANDAGENCY: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForDigitalMicroAndAgency`;
	static STRING_URL_HANDSHAKE_REFRESHTOKENFORPEGADAIAN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_HANDSHAKE}/refreshTokenForPegadaian`;

	//#endregion


	//#region PEGADAIAN CONTROLLER

	static STRING_PATH_CONTROLLER_PEGADAIAN: string = "/Pegadaian";

	static STRING_URL_PEGADAIAN_SELECTPEGADAIANBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PEGADAIAN}/selectPegadaianByEmail`;
	static STRING_URL_PEGADAIAN_SELECTPEGADAIANBYTOKEN: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PEGADAIAN}/selectPegadaianByToken`;
	static STRING_URL_PEGADAIAN_VERIFYPEGADAIANBYEMAIL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PEGADAIAN}/verifyPegadaianByEmail`;
	static STRING_URL_PEGADAIAN_SIGNOUT: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_PEGADAIAN}/signOut`;

	//#endregion


	//#region THIRD PARTY DASHBOARD

	static STRING_PATH_CONTROLLER_THIRDPARTYDASHBOARD: string = "/ThirdPartyDashboard";

	static STRING_URL_THIRDPARTYDASHBOARD_SELECTDASHBOARDPRODUCTPEGADAIANBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_THIRDPARTYDASHBOARD}/selectDashboardProductPegadaianByAttributes`;
	static STRING_URL_THIRDPARTYDASHBOARD_SELECTDASHBOARDPRODUCTPEGADAIANBYATTRIBUTESFOREXCEL: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_THIRDPARTYDASHBOARD}/selectDashboardProductPegadaianByAttributesForExcel`;

	//#endregion


	//#region THIRD PARTY DASHBOARD

	static STRING_PATH_CONTROLLER_DOWNLOADDOCUMENT: string = "/Document";

	static STRING_URL_DOWNLOADDOCUMENT_DOWNLOADDASHBOARDFORPEGADAIANBYROUTE: string = `${WebAddressConstant.STRING_URL_BROADCAST_API}${WebAddressConstant.STRING_PATH_CONTROLLER_DOWNLOADDOCUMENT}/documentForPegadaianByRoute`;

	//#endregion


	//#region BRANCH

	static STRING_PATH_CONTROLLER_BRANCH: string = "/Branch";

	static STRING_URL_BRANCH_SELECTBRANCH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranch`;
	static STRING_URL_BRANCH_SELECTBRANCHCONVENTIONAL: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchConventional`;
	static STRING_URL_BRANCH_SELECTBRANCHFORCONVENTIONALANDSYARIAH: string = `${WebAddressConstant.STRING_URL_USER_API}${WebAddressConstant.STRING_PATH_CONTROLLER_BRANCH}/selectBranchForConventionalAndSyariah`;

	//#endregion


	//#region CLAIM PROPOSAL MONITORING

	static STRING_PATH_CONTROLLER_CLAIMPROPOSAL: string = "/ClaimProposal";

	static STRING_URL_CLAIMPROPOSAL_SELECTCLAIMPROPOSALBYATTRIBUTES: string = `${WebAddressConstant.STRING_URL_INTEGRATION_API}${WebAddressConstant.STRING_PATH_CONTROLLER_CLAIMPROPOSAL}/selectClaimForPegadaianMonitoringByAttributes`;

	//#endregion
}

//#endregion
